<template>
  <footer class="main-footer sticky footer-type-1">
    <div class="footer-inner">
      <!-- 版权信息 -->
      <div class="footer-text">
        Copyright © 2017-2022 0394.CN All rights reserved.
        <a href="https://beian.miit.gov.cn" target="_blank"> 豫ICP备17026032号 </a>
        <a href="http://www.zhiniuer.com" target="_blank"> 执牛耳</a>版权所有
      </div>
      <div class="go-up">
        <a href="#" rel="go-top"><i class="fa-angle-up"></i></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style></style>
